import React from 'react'
import DeveloperResize from '../assets/DeveloperResize.jpeg'
import Cloud1Resize from '../assets/Cloud1Resize.jpeg'

const About = () => {
    return (
        <div name='about' className='lg:w-100% h-100%  bg-[#002436] text-gray-200'>
            <div className='flex flex-col justify-center items-center w-full h-full'>
                <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                    <div className='sm:text-right pb-8 pl-4'>
                        <p className='text-4xl font-bold inline border-b-4 border-[#38afeb]'>
                            About
                        </p>
                    </div>
                    <div></div>
                </div>
                <div className='max-w-[1000px] w-full grid lg:grid-cols-2 gap-8 px-4'>
                    <div className='sm:text-4xl font-bold'>
                        <p className='text-slate-400 mb-4'>Welcome.</p>
                        <p>This website showcases my portfolio of projects, and my passion to build applications to solve real world problems.</p>

                    </div>
                    <div>
                        <img src={DeveloperResize} alt='Developer' className='w-full h-full rounded-xl' />
                    </div>
                    <div className=''>
                        <p>
                            I am a full-stack developer passionate about building dynamic, user-friendly web and native applications. I have experience with multiple programming languages, including JavaScript, TypeScript, and Python. My expertise extends to various front-end and back-end frameworks and libraries, such as React, Next.js, Node.js, Express, Flutter, and SwiftUI.
                            <p className='mt-8'>
                                I have successfully led and deployed numerous applications across platforms like Vercel, Heroku, AWS, Firebase, and the Apple App Store/Google Play.
                            </p>
                        </p>
                    </div>
                    <div>
                        <img src={Cloud1Resize} alt='Developer' className='w-full h-full rounded-xl pb-36' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About