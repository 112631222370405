import React from 'react'
import mysql from '../assets/mysql.png';
import mongo from '../assets/mongo.png';
import react from '../assets/react.png';
import tailwind from '../assets/tailwind.png';
import reduxIcon from '../assets/reduxIcon.png';
import typescript from '../assets/Typescript.png';
import nextjs from '../assets/next-js.svg';
import jwtImage from '../assets/jwtImage.png';

const Skills = () => {
  return (
    <div name='skills' className='bg-[#002436] text-gray-200 pt-10 lg:mb-20'>
        {/* Container */}
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full sm:flex-col'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-[#38afeb]'>Skills</p>
                <p className='py-4'>Technologies I have worked with</p>
            </div>

            <div className='w-full grid grid-cols-2 md:grid-cols-4 gap-4 text-center py-8'>
                <div className='shadow-md shadow-black hover:scale-110 duration-700'>
                    <img className='w-24 mt-6 mx-auto' src={typescript} alt="javascript" />
                    <p className='mt-2'>TypeScript</p>
                </div>
                <div className='shadow-md shadow-black hover:scale-110 duration-700'>
                    <img className='w-20 mx-auto pt-5' src={react} alt="react" />
                    <p>React/React Native</p>
                </div>
                <div className='shadow-md shadow-black hover:scale-110 duration-700'>
                    <img className='w-20 mx-auto mt-4' src={tailwind} alt="tailwind" />
                    <p>Tailwind CSS</p>
                </div>
                <div className='shadow-md shadow-black hover:scale-110 duration-700'>
                    <img className='w-20 mx-auto mt-6' src={reduxIcon} alt="redux" />
                    <p>Redux Toolkit</p>
                </div>
                <div className='shadow-md shadow-black hover:scale-110 duration-700'>
                    <img className='w-20 mt-2 mx-auto' src={nextjs} alt="next" />
                    <p className='mt-2'>Next.js</p>
                </div>
                <div className='shadow-md shadow-black hover:scale-110 duration-700'>
                    <img className='h-20 mt-2   mx-auto' src={mysql} alt="mysql" />
                    <p className='mt-2'>SQL Databases</p>
                </div>
                
                <div className='shadow-md shadow-black hover:scale-110 duration-700'>
                    <img className='w-20 mx-auto pt-4' src={mongo} alt="mongo" />
                    <p>NoSQL Databases</p>
                </div>
                <div className='shadow-md shadow-black hover:scale-110 duration-700'>
                    <img className='w-20 pt-3 mx-auto' src={jwtImage} alt="figma" />
                    <p className='mt-1'>Json Web Tokens</p>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Skills